<template>
  <button
    class="text-slate-600 dark:text-slate-100 w-10 h-10 mt-2 mb-3 p-0 flex items-center justify-center rounded-lg hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600 relative"
    :class="{ 'animate-shake-phone': isThereAnIncomingCall }"
    @click="toggleWebPhone"
  >
    <fluent-icon icon="phone-solid" view-box="0 0 512 512" />
  </button>
</template>
<script>
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      ubluxSDK: null,
      isThereAnIncomingCall: false,
    };
  },
  computed: {
    ubluxSession() {
      return JSON.parse(Cookies.get('ublux_session_info'));
    },
  },
  async mounted() {
    await this.authenticate();
    this.initWebPhone();
  },
  methods: {
    toggleWebPhone() {
      this.ubluxSDK.webphone.toggle();
    },
    initWebPhone() {
      if (this.ubluxSDK) {
        this.ubluxSDK.webphone.init();
        this.ubluxSDK.webphone.render();

        this.ubluxSDK.webphone.on('webphone_error', value => {
          console.log('webphone_error: ', value);
        });
        this.ubluxSDK.webphone.on('incomingCallOnInit', () => {
          // Llamada entrante, deberia de animar
          this.isThereAnIncomingCall = true;
          this.ubluxSDK.webphone.open();
        });
        this.ubluxSDK.webphone.on('incomingCallAnswer', () => {
          // Contestaron la llamada y deberia de dejar de animar
          this.isThereAnIncomingCall = false;
        });
        this.ubluxSDK.webphone.on('incomingCallTerminated', () => {
          // Dejo de sonar y puede que no hayan contestado, entonces deberia de dejar de animar
          this.isThereAnIncomingCall = false;
        });
      }
    },
    async authenticate() {
      this.ubluxSDK = await window.UbluxSDK.init({
        authorization: {
          access_token: this.ubluxSession.accessToken,
        },
      });
    },
  },
};
</script>
